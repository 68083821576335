<template>
<div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

        <!-- Login v1 -->
        <b-card class="mb-0">
            <b-link class="brand-logo" v-show="loadingLogo==false">
                <b-img :src="resolveLogo()" alt="Logo" style="width: 55%; margin-bottom: 1.5rem;" />
            </b-link>
            <div style="height: 2rem;" v-show="loadingLogo==true"></div>

            <!-- form -->
            <validation-observer ref="loginForm" #default="{invalid}">
                <b-form class="auth-login-form mt-2" @submit.prevent="login">

                    <!-- email -->
                    <b-form-group label-for="email" label="Email">
                        <validation-provider #default="{ errors }" name="Email" rules="required|email">
                            <b-form-input id="email" v-model="userEmail" name="login-email" :state="errors.length > 0 ? false:null" placeholder="lucca@example.com" autofocus />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- password -->
                    <b-form-group>
                        <div class="d-flex justify-content-between">
                            <label for="password"> {{ $t('Password')}}</label>

                        </div>
                        <validation-provider #default="{ errors }" name="Password" rules="required">
                            <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                                <b-form-input id="password" v-model="password" :type="passwordFieldType" class="form-control-merge" :state="errors.length > 0 ? false:null" name="login-password" placeholder="Password" />

                                <b-input-group-append is-text>
                                    <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- submit button -->
                    <b-button variant="primary" type="submit" block :disabled="invalid">
                        {{ $t('Sign in')}}
                    </b-button>
                </b-form>
            </validation-observer>

            <div class="divider my-2">
                <div class="divider-text">
                    {{ $t('or')}}
                </div>
            </div>

            <b-card-text class="text-center mt-2">
                <span> {{ $t('if you prefer, sign in using our')}}</span>
                <b-link :to="{name:'auth-forgot-password'}">
                    <span> {{ $t('access token')}}</span>
                </b-link>
            </b-card-text>

        </b-card>
        <!-- /Login v1 -->
    </div>
</div>
</template>

<script>
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BImg
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
    required,
    email
} from '@validations'
import {
    togglePasswordVisibility
} from '@core/mixins/ui/forms'

import signalrHub from '@core/signalr/signalrHub.js'

import useJwt from '@/auth/jwt/useJwt'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import customerSettigns from '@core/utils/customerSettigns'

import store from '@/store'

import systemStoreModule from '@core/layouts/components/app-navbar/systemStoreModule'

import { onUnmounted} from '@vue/composition-api'

export default {
    components: {
        // BSV
        BButton,
        BImg,
        BForm,
        BFormInput,
        BFormGroup,
        BCard,
        BCardTitle,
        BLink,
        VuexyLogo,
        BCardText,
        BInputGroup,
        BInputGroupAppend,
        BFormCheckbox,
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            status: '',
            password: '',
            userEmail: '',
            //sideImg: require('@/assets/images/pages/login-v2.svg'),

            // validation rules
            required,
            email,
            userSettings: null,
            loadingLogo: true

        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
    methods: {
        async getSetings() {
            this.loadingLogo = true
            this.userSettings = await customerSettigns()
            this.loadingLogo = false
        },
        resolveLogo() {

            console.log(`userSettings`, this.userSettings)
            if (!this.userSettings) {

                return require('@/assets/images/logo/LogoExtend.png')
            } else {

                return this.userSettings.logoUrl
            }
        },

        login() {

            this.$refs.loginForm.validate().then(success => {
                if (success) {
                    try {
                        useJwt.login({
                                email: this.userEmail,
                                password: this.password,
                            })
                            .then(response => {
                                console.log('RESPONSE LOGIN ', response)
                                const {
                                    userData
                                } = response.data
                                useJwt.setToken(response.data.accessToken)
                                useJwt.setRefreshToken(response.data.refreshToken)
                                localStorage.setItem('userData', JSON.stringify(userData))
                                this.$ability.update(userData.ability)
                                this.$i18n.locale = userData.language

                                // ? This is just for demo purpose as well.
                                // ? Because we are showing eCommerce app's cart items count in navbar
                                //this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

                                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                                //this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                                this.$router.replace("/")
                                    .then(() => {
                                        this.$toast({
                                            component: ToastificationContent,
                                            position: 'top-right',
                                            props: {
                                                title: `Welcome ${userData.fullName || userData.username}`,
                                                icon: 'CoffeeIcon',
                                                variant: 'success',
                                                text: `You have successfully logged in as ${userData.role}.`,
                                            },
                                        })
                                    })
                            })
                    } catch (error) {
                        console.log('DEU ERRO ...')
                    }

                }
            })
        },
    },
    created() { // mounted
        this.getSetings()
    },
    setup() {
        const SYSTEM_APP_STORE_MODULE_NAME = 'app-system'

        // Register module
        if (!store.hasModule(SYSTEM_APP_STORE_MODULE_NAME)) store.registerModule(SYSTEM_APP_STORE_MODULE_NAME, systemStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(SYSTEM_APP_STORE_MODULE_NAME)) store.unregisterModule(SYSTEM_APP_STORE_MODULE_NAME)
        })
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
